import React, { Component } from 'react';
import { MenuItems } from "./MenuItems"
import './Navbar.css'
import logoArrasa from '../../ressources/images/full/logo_white_letters.png';

class Navbar extends Component {
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        return(
            <nav className="NavbarItems">
                <h1 className="navbar-logo"><a href="/"><img src={logoArrasa} alt="logo_alecrit_white"/></a></h1>
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                <div className="dropdown">
                    <button className="dropbtn"> <a>Prestations</a>  
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                    <a href="/prestations-professionnels">Pour les professionnels</a>
                    <a href="/prestations-particuliers">Pour les particuliers</a>
                    </div>
                </div> 
                    {MenuItems.map((item, index) => {
                            return (  
                                <li key={index}>
                                    <a className={item.cName} href={item.url}>
                                    {item.label}
                                    </a>
                                </li>
                            )
                    })}
                </ul>
            </nav>
        )
    }
}

export default Navbar