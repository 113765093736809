import React from 'react';
import Navbar from "./components/Navbar/Navbar";
import './App.css';

import HomePage from './pages/HomePage/HomePage'
import PlayDetails from './pages/PlayDetails/PlayDetails'
import PlayDescriptionModal from './components/OurCompanies/PlayDescriptionModal/PlayDescriptionModal'
import PlayDescriptionModalTrailerLess from './components/OurCompanies/PlayDescriptionModal/PlayDescriptionModalTrailerLess'

import Contact from './components/FooterWithContact/FooterWithContact'

import {Plays} from './components/OurCompanies/resources/plays';

import { BrowserRouter,Switch,Route } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
                <title>ARRASA. Producción y Distribución</title>
                <link rel="canonical" href="https://www.arrasa.org/" />
      </Helmet>

      <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
              <meta name="apple-mobile-web-app-capable" content="yes"></meta>
              <meta name="mobile-web-app-capable" content="yes"></meta>
      </Helmet>

      <BrowserRouter>
        {/* <Navbar /> */}
          <Switch>
            <Route exact path="/" children={<HomePage/>} />
            {Plays.map((item, index) => {
              if(item.released)
              {
                return (
                  <Route exact path={`/details/${item.playId}`} children={<PlayDescriptionModal play={item}/>}/>
                )
              }
              else
              {
                return (
                  <Route exact path={`/details/${item.playId}`} children={<PlayDescriptionModalTrailerLess play={item}/>}/>
                )
              }
              
            })}
            <Route exact path="/*">
              <HomePage/>
            </Route>
          </Switch>
      </BrowserRouter>
      <Contact/>
    </div>
  );
}

export default App;