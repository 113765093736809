import React, { Component } from 'react';
import './companyDetails.css';
import Carousel from "react-multi-carousel";
import {Plays} from '../resources/plays';
import plusImage from '../../../ressources/images/full/plus.png'
import "react-multi-carousel/lib/styles.css";


class CompanyDetails extends Component {

    constructor() {
        super();
        this.responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 2 // change to 3
            },
            desktop: {
                breakpoint: { max: 3000, min: 1600 },
                items: 2 // change to 3
            },
            tablet: {
                breakpoint: { max: 1600, min: 910 },
                items: 2 //change to 2
            },
            mobile: {
                breakpoint: { max: 910, min: 0 },
                items: 1
            }
        }
    }
    
    render() {
        if (this.props.state.companyActive === false || this.props.state.playActive === true ||this.props.state.toShowId !== this.props.company.companyID){return false;}

        return(
            <div className="companyDetailsWrapper">
                <div className="firstColumnWrapper">
                    <div className="companyNameWrapper">
                        <h1>{this.props.company.Name}</h1>
                    </div>
                    <div className="companyDescriptionWrapper">
                        <p dangerouslySetInnerHTML={{__html: this.props.company.descriptionFormatted}}></p>
                    </div>
                    <div className="CompanyPhotoWrapper">
                        <img src={this.props.company.imageDescription} alt="coverPhoto"></img>
                    </div>
                </div>
                <div className="secondColumnWrapper">
                    {(() => {
                            if(this.props.company.numberPlays == 1)
                            {
                                return (
                                    <div className="SinglePlayWrapper">
                                        {Plays.map((item, index) => {
                                            if(item.companyID == this.props.company.companyID)
                                            {
                                                if(item.afficheOrientation === "horizontal")
                                                {
                                                    return (
                                                        <div className="playWrapperHSingle" key={index + 24}>
                                                            <a className="playAfficheH" key={index+10000} style={{ backgroundImage: `url(${item.affiche})`, backgroundSize: "cover"}} onClick={() =>  this.props.handlerPlay(item.playId)}>
                                                                <div className="imageWrapper"><img src={plusImage} alt="plus"></img>
                                                                </div>
                                                            </a>
                                                            <div className="playAfficheDescription">
                                                                <div className="titleWrapper"><h1>{item.Title}</h1><a onClick={() =>  this.props.handlerPlay(item.playId)}><img src={plusImage} alt="plus"></img></a></div>
                                                                <p dangerouslySetInnerHTML={{__html: item.synopsisShort}}></p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else{
                                                    return (
                                                        <div className="playWrapperVSingle" key={index + 24}>
                                                            <a className="playAfficheV" key={index+10000} style={{ backgroundImage: `url(${item.affiche})`, backgroundSize: "cover"}} onClick={() =>  this.props.handlerPlay(item.playId)}>
                                                                <div className="imageWrapper"><img src={plusImage} alt="plus"></img>
                                                                </div>
                                                            </a>
                                                            <div className="playAfficheDescription">
                                                                <div className="titleWrapper"><h1>{item.Title}</h1><a onClick={() =>  this.props.handlerPlay(item.playId)}><img src={plusImage} alt="plus"></img></a></div>
                                                                <p dangerouslySetInnerHTML={{__html: item.synopsisShort}}></p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            } 
                                        })}
                                    </div>
                                )
                            }
                            else
                            {
                                return (
                                    <div className="MultiplePlayWrapper">
                                        <Carousel 
                                            swipeable={false}
                                            draggable={false}
                                            responsive={this.responsive}
                                            ssr={true} // means to render carousel on server-side.
                                            infinite={true}
                                            keyBoardControl={true}
                                            customTransition="all .5"
                                            transitionDuration={500}>  
                                                {Plays.map((item, index) => {
                                                    if(item.companyID == this.props.company.companyID)
                                                    {
                                                        return (
                                                            <div className="playWrapperColumn" key={index + 24}>
                                                                <a className="playAffiche" key={index+10000} style={{ backgroundImage: `url(${item.affiche})`, backgroundSize: "cover"}} onClick={() =>  this.props.handlerPlay(item.playId)}>
                                                                    <div className="imageWrapper"><img src={plusImage} alt="plus"></img>
                                                                </div>
                                                                </a>
                                                                <div className="playAfficheDescription">
                                                                    <div className="titleWrapper"><h1>{item.Title}</h1><a onClick={() =>  this.props.handlerPlay(item.playId)}><img src={plusImage} alt="plus"></img></a></div>
                                                                    <p dangerouslySetInnerHTML={{__html: item.synopsisShort}}></p>
                                                                </div>
                                                            </div>
                                                        )
                                                    } 
                                                })}
                                            </Carousel>
                                    </div>
                                )
                            }
                    })()}
                </div>
                <a className="close" onClick={this.props.close}></a>
                <a className="commandsBar"></a>
            </div>
        )
    }
}

export default CompanyDetails