import asuncion from './../../ressources/images/full/asuncion.png';
import raquel from './../../ressources/images/full/raquel.jpg';
import sandra from './../../ressources/images/full/sandra.jpeg';

export const Profiles = [
    {
        Name: 'Raquel Anaya',
        description:'<p>Licenciada en Historia del Arte, con una trayectoria profesional de más de 15 años, ligada a la Gestión Cultural coordinando proyectos desde la empresa privada para diferentes entidades públicas. De 2020 a 2022, ha sido secretaria general de la Asociación de Profesionales de la Gestión Cultural en Castilla y León (GESCULCyL) de la que es miembro así como pertenece, también, a la Asociación Profesional Española de Historiadores del Arte (APROHA). <br><br>En enero de 2022 creó junto a Asunción Blanco, <b>ARRASA, Producción & Distribución</b> donde ejerce como gestora cultural y distribuidora en el sector de las artes escénicas. <br><br>Actualmente, ostenta el cargo de directora en ARRASA tras el fallecimiento en noviembre de 2022, de su mentora, socia y amiga, Asunción Blanco.</p>',
        image: raquel
    },
    {
        Name: 'Asunción Blanco',
        description:'<p> Productora y distribuidora de artes escénicas con más de 30 años de experiencia profesional que se extiende, también, a otros ámbitos culturales y a la organización de eventos. En 2022, crea <b>ARRASA. Producción & Distribución</b>, siendo su ‘alma mater’ y directora hasta su triste fallecimiento en noviembre de ese mismo año.</p>',
        image: asuncion
    },

  //  {
  //      Name: 'Sandra Carrasco',
  //      description:'<p> Artista de circo profesional, estilista y diseñadora en diferentes compañías profesionales de artes escénicas nacionales e internacionales: <i>Gandini Juggling, Invisible circus y Cía Albadulake</i>. Ha sido Presidenta de la asociación <i>De Fábula</i> y forma parte del equipo de <b>ARRASA. Producción & Distribución</b> en calidad de colaboradora en la distribución de artes escénicas.</p>',
  //      image: sandra
  //  }
]