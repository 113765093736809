import React, { Component } from 'react';
import './EntryBanner.css'
import mainLogo from '../../ressources/images/full/logo_complete.png';
import arrowDown from '../../ressources/images/full/entryArrowDown.svg'

class EntryBanner extends Component {

    scrollToFct(linkTarget) {   
        var offset = linkTarget.pageY;
        window.scrollTo({
            top: offset,
            behavior: 'smooth',
          })
    } 
    
    render() {
        return(
            <div className="EntryWrapper">
                <div id="entry" className="ContainerEntry">
                    <center>
                        <img src={mainLogo} alt="logo_arrasa" className="EntryLogo" />
                    </center>
                </div>
                <center>
                    <a onClick={this.scrollToFct}>
                        <img src={arrowDown} alt="arrow_down" className="ArrowDown" />
                    </a>
                </center>
            </div>
        )
    }
}

export default EntryBanner