import React, { Component } from 'react';
import './playDetails.css';

class PlayDetails extends Component {  
    render() {
        if (this.props.state.companyActive === true || this.props.state.playActive === false ||this.props.state.toShowPlayId !== this.props.play.playId){return false;}

        return(
            // Title: 'Eterno. Homenaje flamenco a Pablo Picasso.',
            // credits:'Dirección artística y coreográfica: Carlos Rodríguez, Composición sinfónica original: Lucas Vidal, Diseño de escenografía: Alesio Meloni, Diseño de iluminación: Luis Perdiguero, Diseño de vestuario: Yaiza Pinillos, Artistas: Carlos Rodríguez, Olga Pericet, Mayte Bajo, La Lupi, Carmen Angulo, Sara Calero, David, Coria, Jonathan Miró, Miguel Ángel Berna y la colaboración especial de Joaquín de Luz.',
            // synopsisShort:'Eterno es una obra contada a través del lenguaje de la estilización de la danza española y del flamenco. El espectáculo rinde un emocionante homenaje al artista español más universal del siglo XX, Pablo Ruiz Picasso, con motivo del 50 aniversario de su muerte. La coreografía de Eterno, regala el alma y la raíz de su expresión encarnadas sobre las tablas.Algo así como ofrecerle un púlpito a lo no dicho para así, crear un show tan maravilloso como necesario. Las palabras de Picasso, sus colores y su luz encienden la imaginación haciéndola volar del corazón al aire.',
            // synopsis:'<p> Eterno es una obra contada a través del lenguaje de la estilización de la danza española y del flamenco. El espectáculo rinde un emocionante homenaje al artista español más universal del siglo XX, Pablo Ruiz Picasso, con motivo del 50 aniversario de su muerte.</p> <p> La coreografía de Eterno, regala el alma y la raíz de su expresión encarnadas sobre las tablas.</p> <p>La coreografía de Eterno, regala el alma y la raíz de su expresión encarnadas sobre las tablas.Algo así como ofrecerle un púlpito a lo no dicho para así, crear un show tan maravilloso como necesario.</p> <p> Las palabras de Picasso, sus colores y su luz encienden la imaginación haciéndola volar del corazón al aire. </p>',
            // bannerImage: bannerImageCarlosRodriguez1,
            // dossierFile: dossierCarlosRodriguez1,
            // affiche: afficheCarlosRodriguez1,
            // companyID: 'carlosRodriguez',
            // trailerURL: trailerPlaceholderCarlosRodriguez1,
            // released: false,
            // playId:'eterno',
            // afficheOrientation: "horizontal"

            <div className="playDetailsWrapper">
                <div className="playTopWrapper">
                    <h1>{this.props.play.Title}</h1>
                    <p dangerouslySetInnerHTML={{__html: this.props.play.synopsis}}></p>
                </div>
                <div className="playBottomWrapper">
                    <div className="playBottomFirstColumnWrapper">
                        <h1>Créditos</h1>
                        <ul>
                            {this.props.play.credits.map(credit => <li key={credit} dangerouslySetInnerHTML={{__html: credit}}></li>)}
                        </ul>                    
                        <a className="buttonDownloadDossier" href={this.props.play.dossierFile} download>DESCARGAR DOSIER DE LA OBRA</a>
                    </div>
                    <div className="playBottomSecondColumnWrapper">
                        {(() => {
                            if (this.props.play.released == false) {
                                return (
                                    <div className="TrailerPlaceHolderContainer" style={{ backgroundImage: `url(${this.props.play.trailerURL})`, backgroundSize: "cover"}}></div>
                                )
                            }
                            else
                            {
                                return (
                                    <div className="TrailerContainer" >
                                        <iframe width="100%" height="100%"
                                            frameBorder="0"
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen="allowfullscreen"
                                            mozallowfullscreen="mozallowfullscreen" 
                                            msallowfullscreen="msallowfullscreen" 
                                            oallowfullscreen="oallowfullscreen" 
                                            webkitallowfullscreen="webkitallowfullscreen"
                                            src={this.props.play.trailerURL}>
                                        </iframe>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
                <a className="arrow arrow-left" onClick={this.props.back}></a>
                <a className="close" onClick={this.props.close}></a>
                <a className="commandsBar"></a>
            </div>
        )
    }
}

export default PlayDetails