import React, { Component } from 'react';
import './OurCompanies.css';
import {Companies} from './resources/companiesList'
import CompanyDescriptionModalSingle from './CompanyDescriptionModal/CompanyDescriptionModalSingle'
import CompanyDescriptionModalMultiple from './CompanyDescriptionModal/CompanyDescriptionModalMultiple'
import Button from 'react-scroll/modules/components/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CompanyDetails from './companyDetails/companyDetails';
import plusImage from '../../ressources/images/full/plus.png'
import {Plays} from './resources/plays'
import PlayDetails from './playDetails/playDetails';

// Agathe tiene que descargar la foto de la mancha azul sin el +
class OurCompanies extends Component {
    constructor(props) {
        super(props);

        this.counterAdults = 0;
        this.counterFamiliar = 0;
        this.counterCollaboration = 0;
        this.listOfTheatreCompanies = [];
        this.listOfFamiliarCompanies = [];
        this.listOfColaboracionesCompanies = [];

        this.state = {
            companyActive:false,
            playActive:false,
            toShowId:"false",
            toShowPlayId:"false",
            toShowCategory:"false"
        };  

        this.handlerClose = this.close.bind(this);
        this.handlerBack = this.back.bind(this);
        this.handlerPlayComponent = this.openPlayComponent.bind(this);
    }

    openCompanyComponent(companyId,type) {
        this.setState({ companyActive:true });
        this.setState({ playActive:false });
        this.setState({ toShowId:companyId });
        this.setState({ toShowPlayId:"false" });
        this.setState({ toShowCategory:type });
        console.log(this.state)
    }

    openPlayComponent(playId) {
        this.setState({ companyActive:false });
        this.setState({ playActive:true });
        this.setState({ toShowPlayId:playId });
        console.log(this.state)
    }

    back() {
        this.setState({ companyActive:true });
        this.setState({ playActive:false });
        this.setState({ toShowPlayId:"false" });
        console.log(this.state)
    }

    close() {
        this.setState({ companyActive:false });
        this.setState({ playActive:false });
        this.setState({ toShowId:"false" });
        this.setState({ toShowPlayId:"false" });
        this.setState({ toShowCategory:"false" });
        console.log(this.state)
    }

    DefaultSection(props) {
        const category = props.category;
        const counter = props.counter;
        if (counter===0) {
            if(props.category==="teatroAdultos") return <p>The default thingy adultos</p>
            else if(props.category==="teatroFamiliar") return <p>The default thingy familiar</p>
            else if(props.category==="colaboraciones") return <p>The default thingy colaboraciones</p>
        }
    }
    
    // Cambiar que no se abran todos a la vez, hay algun problema con los estados.

    render() {
        return(
            <div className="ourCompaniesWrapper">
                <div className="titleWrapper">
                    <h1>Nuestras Compañías</h1>
                </div>

                <div className="adultCompaniesWrapper">
                    <div className="adultsTitleWrapper">
                        <h1>Teatro para adultos</h1>
                    </div>

                    <div className="companiesWrapper">
                        {Companies.map((item, index) => {
                            if(item.category === "teatroAdultos")
                            {
                                this.counterAdults ++;
                                return(
                                    <a className="companyWrapper" onClick={() =>  this.openCompanyComponent(item.companyID,"adults")} key={index+10000} style={{ backgroundImage: `url(${item.imageCover})`, backgroundSize: "cover"}}>
                                        <h1>{item.Name}</h1>
                                        {(() => {
                                            if (this.state.toShowId !== item.companyID) {
                                                return (
                                                    <div className="knowMore"><p>{item.Name}</p><img src={plusImage} alt="plus"></img></div>
                                                )
                                            }
                                        })()}
                                    </a>
                                )
                            }
                        })}

                        {(() => {
                            if (this.counterAdults == 0) {
                                return (
                                    <div className="companyWrapper" key={10000}> 
                                        <p>The default thingy adultos</p>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                    
                    {(() => {
                        if ((this.state.companyActive == true || this.state.playActive == true) && (this.state.toShowCategory == "adults")) {
                            return(
                                <div className="allCompanyDetailsWrapperAdultos">
                                    {Companies.map((item, index) => {
                                        if(item.category === "teatroAdultos")
                                        {
                                            this.listOfTheatreCompanies.push(item.companyID)
                                            this.counterAdults ++;
                                            return(
                                                // eslint-disable-next-line no-restricted-globals
                                                <CompanyDetails company={item} state={this.state} handlerPlay={this.handlerPlayComponent} close = {this.handlerClose}></CompanyDetails>
                                            )
                                        }
                                    })}
                                    
                                    {Plays.map((play, index) => {
                                        for (index = 0; index < this.listOfTheatreCompanies.length; index++) {
                                            if(play.companyID === this.listOfTheatreCompanies[index])
                                            {
                                                return(
                                                    // eslint-disable-next-line no-restricted-globals
                                                    <PlayDetails play={play} state={this.state} back={this.handlerBack} close={this.handlerClose}></PlayDetails>
                                                )
                                            }
                                        }
                                    })}               
                                </div>
                            )
                        }
                    })()}
                </div>
                

                <div className="familiarCompaniesWrapper">
                    <div className="familiarTitleWrapper">
                        <h1>Teatro familiar</h1>
                    </div>

                    <div className="companiesWrapper">
                        {Companies.map((item, index) => {
                            if(item.category === "teatroFamiliar")
                            {
                                this.counterFamiliar ++;
                                return(
                                    <a className="companyWrapper" onClick={() =>  this.openCompanyComponent(item.companyID,"familiar")} key={index+10000} style={{ backgroundImage: `url(${item.imageCover})`, backgroundSize: "cover"}}>
                                        <h1>{item.Name}</h1>
                                        {(() => {
                                            if (this.state.toShowId !== item.companyID) {
                                                return (
                                                    <div className="knowMore"><p>{item.Name}</p><img src={plusImage} alt="plus"></img></div>
                                                )
                                            }
                                        })()}
                                    </a>
                                )
                            }
                        })}

                        {(() => {
                            if (this.counterFamiliar == 0) {
                                return (
                                    <div className="companyWrapper" key={10000}> 
                                        <p>The default thingy familiar</p>
                                    </div>
                                )
                            }
                        })()}
                    </div>

                    {(() => {
                        if ((this.state.companyActive == true || this.state.playActive == true) && (this.state.toShowCategory == "familiar")) {
                            return(
                                <div className="allCompanyDetailsWrapperFamiliar">
                                    {Companies.map((item, index) => {
                                        if(item.category === "teatroFamiliar")
                                        {
                                            this.listOfFamiliarCompanies.push(item.companyID)
                                            this.counterFamiliar ++;
                                            return(
                                                // eslint-disable-next-line no-restricted-globals
                                                < CompanyDetails company={item} state={this.state} handlerPlay={this.handlerPlayComponent} close = {this.handlerClose}></CompanyDetails>
                                            )
                                        }
                                    })}
                                    
                                    {Plays.map((play, index) => {
                                        for (index = 0; index < this.listOfFamiliarCompanies.length; index++) {
                                            if(play.companyID === this.listOfFamiliarCompanies[index])
                                            {
                                                return(
                                                    // eslint-disable-next-line no-restricted-globals
                                                    <PlayDetails play={play} state={this.state} back={this.handlerBack} close={this.handlerClose}></PlayDetails>
                                                )
                                            }
                                        }
                                    })}
                                </div>
                                )
                            }
                    })()}
                </div>


                {/* <div className="colaboracionesCompaniesWrapper">
                    <div className="colaboracionesTitleWrapper">
                        <h1>Colaboraciones</h1>
                    </div>

                    <div className="companiesWrapper">
                        {Companies.map((item, index) => {
                            if(item.category === "colaboraciones")
                            {
                                this.counterCollaboration ++;
                                return(
                                    <a className="companyWrapper" onClick={() =>  this.openCompanyComponent(item.companyID,"colabs")} key={index+10000} style={{ backgroundImage: `url(${item.imageCover})`, backgroundSize: "cover"}}>
                                        <h1>{item.Name}</h1>
                                        {(() => {
                                            if (this.state.toShowId !== item.companyID) {
                                                return (
                                                    <div className="knowMore"><p>{item.Name}</p><img src={plusImage} alt="plus"></img></div>
                                                )
                                            }
                                        })()}
                                    </a>
                                )
                            }
                        })}

                        {(() => {
                            if (this.counterCollaboration == 0) {
                                return (
                                    <div className="companyWrapper" key={10000}> 
                                        <p>The default thingy familiar</p>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                    
                    {(() => {
                        if ((this.state.companyActive == true || this.state.playActive == true) && (this.state.toShowCategory == "colabs")) {
                            return(
                                <div className="allCompanyDetailsWrapperColabs">
                                    {Companies.map((item, index) => {
                                        if(item.category === "colaboraciones")
                                        {
                                            this.listOfColaboracionesCompanies.push(item.companyID)
                                            this.counterCollaboration ++;
                                            return(
                                                // eslint-disable-next-line no-restricted-globals
                                                < CompanyDetails company={item} state={this.state} handlerPlay={this.handlerPlayComponent} close = {this.handlerClose}></CompanyDetails>
                                            )
                                        }
                                    })}
                                    
                                    {Plays.map((play, index) => {
                                        for (index = 0; index < this.listOfColaboracionesCompanies.length; index++) {
                                            if(play.companyID === this.listOfColaboracionesCompanies[index])
                                            {
                                                return(
                                                    // eslint-disable-next-line no-restricted-globals
                                                    <PlayDetails play={play} state={this.state} back={this.handlerBack} close={this.handlerClose}></PlayDetails>
                                                )
                                            }
                                        }
                                    })}
                                </div>
                                )
                            }
                    })()}
                </div> */}
            </div>
        )
    }
}

export default OurCompanies