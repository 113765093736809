//import afficheCarlosRodriguez1 from './../../../ressources/images/full/carlosRodriguez/plays/affiche-1.png';
//import bannerImageCarlosRodriguez1 from './../../../ressources/images/full/carlosRodriguez/plays/banner-1.png';
//import trailerPlaceholderCarlosRodriguez1 from './../../../ressources/images/full/carlosRodriguez/plays/trailer-placeholder-1.png';
//import dossierCarlosRodriguez1 from './dossiers/dossierCarlosRodriguez-1.pdf';

//import afficheAlbadulake1 from './../../../ressources/images/full/albadulake/plays/affiche-1.png';
//import bannerImageAlbadulake1 from './../../../ressources/images/full/albadulake/plays/banner-1.png';
//import dossierAlbadulake1 from './dossiers/Dosier GENOMA B 2023.pdf';

import afficheAlbadulake2 from './../../../ressources/images/full/albadulake/plays/affiche-2.png';
import bannerImageAlbadulake2 from './../../../ressources/images/full/albadulake/plays/banner-2.jpg';
import dossierAlbadulake2 from './dossiers/Dosier SERRANA  2023.pdf';

import afficheAlbadulake3 from './../../../ressources/images/full/albadulake/plays/La_Torre.jpg';
import trailerPlaceholderTorre from './../../../ressources/images/full/albadulake/plays/LaTorre2.png';

//import bannerImageAlbadulake3 from './../../../ressources/images/full/albadulake/plays/banner-2.jpg';
//import dossierAlbadulake3 from './dossiers/Dosier SERRANA  2023.pdf';


//import afficheColl1 from './../../../ressources/images/full/collectiuIntermitent/plays/affiche-1.png';
//import bannerImageColl1 from './../../../ressources/images/full/collectiuIntermitent/plays/banner-1.png';
//import dossierColl1 from './dossiers/dossierColl-1.pdf';

//import afficheColl2 from './../../../ressources/images/full/collectiuIntermitent/plays/affiche-2.png';
//import bannerImageColl2 from './../../../ressources/images/full/collectiuIntermitent/plays/banner-2.png';
//import trailerPlaceholderColl2 from './../../../ressources/images/full/collectiuIntermitent/plays/trailer-placeholder-2.png';
//import dossierColl2 from './dossiers/dossierColl-2.pdf';

//import afficheFN1 from './../../../ressources/images/full/factoriaNorte/plays/affiche-1.png';
//import bannerImageFN1 from './../../../ressources/images/full/factoriaNorte/plays/banner-1.png';
//import dossierFN1 from './dossiers/dossierFN-1.pdf';
//import trailerPlaceholderFN1 from './../../../ressources/images/full/factoriaNorte/plays/trailer-placeholder-1.png';

//import afficheFN2 from './../../../ressources/images/full/factoriaNorte/plays/affiche-2.png';
//import bannerImageFN2 from './../../../ressources/images/full/factoriaNorte/plays/banner-2.png';
//import dossierFN2 from './dossiers/dossierFN-2.pdf';

import afficheArtes1 from './../../../ressources/images/full/artesTeatro/plays/affiche-1.png';
import bannerImageArtes1 from './../../../ressources/images/full/artesTeatro/plays/banner-1.png';
import dossierArtes1 from './dossiers/dossierArtes-1.pdf';

import afficheArtes2 from './../../../ressources/images/full/artesTeatro/plays/affiche-2.png';
import bannerImageArtes2 from './../../../ressources/images/full/artesTeatro/plays/banner-2.png';
import dossierArtes2 from './dossiers/dossierArtes-2.pdf';

import afficheArtes3 from './../../../ressources/images/full/artesTeatro/plays/affiche-3.png';
//import bannerImageArtes3 from './../../../ressources/images/full/artesTeatro/plays/banner-2.png';
//import dossierArtes3 from './dossiers/dossierArtes-3.pdf';
//import trailerPlaceholderKatia from './../../../ressources/images/full/artesTeatro/plays/proximamente-3.png';

import afficheKamante from './../../../ressources/images/full/kamante/plays/affiche-1.png';
import bannerImageKamante from './../../../ressources/images/full/kamante/plays/banner-1.png';
import dossierKamante from './dossiers/dossierKamante-1.pdf';

//import dossierIker from './dossiers/Dosier NO PLEASURE 2023.pdf'
import afficheIker from './../../../ressources/images/full/ikerKarrera/plays/the_room.png';

import afficheAlfonsoX from './../../../ressources/images/full/MariaMelo/plays/Alfonso-X.jpg';
import afficheNadaNiNadie from './../../../ressources/images/full/MariaMelo/plays/NadaNiNadie.png';
import affichePuenteAlMasAlla from './../../../ressources/images/full/MariaMelo/plays/UnPuenteAlMasAlla.jpg';
import afficheJuegoEmbustes from './../../../ressources/images/full/MariaMelo/plays/ElJuegoDeLosEmbustes.jpg';
//import afficheElRegaloZeus from './../../../ressources/images/full/ElRegaloZeus/plays/affiche-1.jpg';

export const Plays = [
    // {
    //     Title: 'Eterno. Homenaje flamenco a Pablo Picasso.',
    //     credits:['<b>Dirección artística y coreográfica:</b> Carlos Rodríguez', '<b>Composición sinfónica original:</b> Lucas Vidal', '<b>Diseño de escenografía:</b> Alesio Meloni', '<b>Diseño de iluminación:</b> Luis Perdiguero', '<b>Diseño de vestuario:</b> Yaiza Pinillos', '<b>Artistas:</b> Carlos Rodríguez, Olga Pericet, Mayte Bajo, La Lupi, Carmen Angulo, Sara Calero, David, Coria, Jonathan Miró, Miguel Ángel Berna y la colaboración especial de Joaquín de Luz.'],
    //     synopsisShort:'Eterno es una obra contada a través del lenguaje de la estilización de la danza española y del flamenco. Es un espectáculo dirigido y protagonizado por Carlos Rodríguez, que rinde un emocionante homenaje al artista español más universal del siglo XX, Pablo Ruiz Picasso, con motivo del 50 aniversario de su muerte. Eterno tiene previsto su estreno en 2022 con el fin de girar por España y el extranjero, a lo largo de 2023 homenajeando al pintor malagueño.',
    //     synopsis:'En Eterno, Carlos Rodríguez, inspirado en la sinfonía original compuesta para la ocasión por Lucas Vidal, actúa en compañía de primeras figuras de la música, el flamenco y la danza como Olga Pericet, Mayte Bajo, La Lupi, Carmen Angulo, Sara Calero, David Coria, Jonathan Miró, Miguel Ángel Berna y cuenta con la colaboración especial de Joaquín de Luz. <br> <br> Eterno es un espectáculo en dos actos y, en cada uno de ellos, una pieza coreográfica diferente. La primera parte es una pieza de carácter argumental que, a través de una coreografía renovadora y original que rescata la estilización de la danza flamenca española, transmitirá al espectador qué significó Picasso desde su obra. La segunda, es una suite que recorre diferentes palos del flamenco a través de composiciones musicales originales que contribuyen a mostrar la versatilidad del elenco de bailaores y músicos como contrapunto al concepto del primer acto. Es un segundo acto que muestre la pureza y la verdad de los artistas, su esencia a través de la expresión de sus emociones y movimientos con absoluta libertad, ayudados por impulsos y referencias estéticas como la bata de cola, el mantón, el abanico, las castañuelas...',
    //     bannerImage: bannerImageCarlosRodriguez1,
    //     dossierFile: dossierCarlosRodriguez1,
    //     affiche: afficheCarlosRodriguez1,
    //     companyID: 'carlosRodriguez',
    //     trailerURL: trailerPlaceholderCarlosRodriguez1,
    //     released: false,
    //     playId:'eterno',
    //     afficheOrientation: "horizontal"
    // },
    //{
    //    Title: 'Genoma B',
    //    credits:['<b>Dirección coral:</b> Isabel Martín','<b>Training actoral:</b> Charo Feria','<b>Dirección danza y coreografías y Diseño vestuarios:</b> Ángeles Vázquez','<b>Idea original, Realización, Diseño luces y Escenografia:</b> Juan Antonio Moreno', '<b>Fotografía:</b> Jorge Amestar', '<b>Director de programación Bernarda:</b> Lázaro Esteban', '<b>Producción:</b> Entrearte Al-badulaque S.L.','<b>Artistas:</b> Sandra Carrasco, Noemí Martinez-Chico, Martina Covone, Vivian Friedrich, Ana Esteban, Nane Ramos, Joaquín Muñino, Gerardo Iglesias “El Jayao” y Sebastián Lorca'],
    //    synopsisShort:'Adaptación sin texto de La casa de Bernarda Alba, de Federico García Lorca. Una obra que aúna teatro, música en directo, flamenco, danza y circo. Presente en el Catálogo Circo de PLATEA 2023, ganadora del premio del jurado a Mejor espectáculo en la Feria Ibérica de Fundao (Portugal) en 2021 y premiada en el certamen Territorio Violeta en 2018. Un montaje que vence las leyes de la temporalidad haciendo este espectáculo inmortal.',
    //    synopsis:'<p> Cinco hermanas encarceladas en cinco miriñaques. Cinco mujeres oprimidas por una desviación de esta sociedad, la del “qué dirán” y que encarna a nuestra protagonista con alma de hierro: Bernarda. Un ataúd en escena como punto de fuga de este comienzo. Un muerto que se aparece en vida. Un alma libre que, a modo de trovador, canaliza las emociones y conflictos que viven las cinco hermanas. Nuestras protagonistas, Angustias, Magdalena, Amelia, Martirio y Adela viven sometidas, encarceladas en sus propios miedos y carencias en esta sociedad que no las deja libres. El circo y el flamenco, ayudarán a nuestros seis protagonistas a crear un mundo lleno de emociones, de vivencias, sufrimientos y alegrías que terminará con un desenlace que todos ya conocemos: Adela, de la mano de su ángel, traspasará la puerta de la libertad como único camino de su salvación. Todo ello contado a través de un montaje que, a nadie deja indiferente debido a la gran fuerza visual que aportan el cante jondo, los números circenses, el baile flamenco y la danza contemporánea. Todo ello supone una exquisita mezcla de gran belleza estética y fuerza emocional.',
    //    bannerImage: bannerImageAlbadulake1,
    //    dossierFile: dossierAlbadulake1,
    //    affiche: afficheAlbadulake1,
    //    companyID: 'albadulake',
    //    trailerURL: 'https://www.youtube.com/embed/oCCzra3IB4U',
    //    released: true,
    //    playId:"genoma_b",
    //    afficheOrientation: "vertical"
    //},
    {
        Title: 'La Torre',
        credits:['<b>Texto: </b> Pedro Calderón de la Barca', '<b>Texto adicional: </b> Luis Guridi', '<b>Idea original: </b> Ángeles Vázquez', '<b>Dirección:</b> Ángeles Vázquez y Juan Antonio Moreno','<b>Intérprete: </b> Luis Valle', '<b>Coreografía: </b> Ángeles Vázquez, <b>Circo: </b> Juan Antonio Moreno', '<b>Videocreación y mapping: </b> Alejandro Pachón', '<b>Voz en off: </b> Domingo Cruz', '<b>Música y sonido: </b> Álvaro Rodriguez Barroso', '<b>Vestuario: </b> Ángeles Vázquez', '<b>Escenografía y luces: </b> Juan Antonio Moreno', '<b>Fotografía: </b> Jorge Armestar', '<b>Coproducción: </b> ENTREARTE AL-BADULAQUE S.L. y FESTIVAL DE TEATRO CLÁSICO DE CÁCERES'],
        synopsisShort:'“La Torre” es la nueva coproducción de la Cía. Albadulake y el Consorcio del Gran Teatro de Cáceres para el Festival de Teatro Clásico de Cáceres 2024. Se estrenó el pasado 26 de junio y, ha sido uno de los espectáculos seleccionados para participar en la Feria de Teatro de Castilla y León. “La Torre” es una apuesta unipersonal que se centra en el personaje de Segismundo, no es una reescritura de la obra de Calderón sino una lectura a través de la danza, el circo y la proyección de imágenes holográficas.',
        synopsis:'<p> El interés de Cía. Albadulake por “La vida es sueño” de Calderón de la Barca y, sobre todo, por los monólogos de Segismundo que cierran con el famoso «que toda la vida es sueño y los sueños sueños son» surge del reto que supone armonizar hoy en día, la verdad de nuestra existencia con la realidad virtual y los sueños. Segismundo no acierta a saber qué parte es vigilia y qué sueño. Su confusión se parece a la que muchas personas sienten estos días, en las que, rodeadas de relatos interesados y contradictorios, les cuesta distinguir la realidad de las ficciones.</p><p>Nuestro Seg, protagonista de “La Torre” hace un recorrido inverso y en paralelo al de Segismundo en ‘La vida es sueño’; Seg ha vivido con completa libertad y en plena posesión de su identidad. Pero todo se complica a medida que su relación con La Torre se estrecha. Seg se hace dependiente de una red que lo va seduciendo, donde el metaverso y las diferentes plataformas de redes sociales distorsionan la verdad y le hacen caer en una vida en paralelo, no vivida, sino recreada virtualmente. Sus miedos, paranoias e inseguridades son el mejor caldo de cultivo para quedar atrapado en La Torre. La voluntad humana y el poder de cambio serán su única salida. <p> ',
        //bannerImage: bannerImageAlbadulake1,
        dossierFile: 'https://drive.google.com/file/d/1UFVxaIptDa4TXIx-9_ORMRibe-K4sGFn/view?usp=drive_link',
        affiche: afficheAlbadulake3,
        companyID: 'albadulake',
        trailerURL: 'https://player.vimeo.com/video/995863826',
        released: true,
        playId:"latorre",
        afficheOrientation: "vertical"
    },
    {
        Title: 'Serrana',
        credits: ['<b>Dramaturgia y Dirección:</b> Ángeles Vázquez Ortega', '<b>Codirección:</b> Juan Antonio Moreno','<b>Música:</b> Milo ke Mandarini', '<b>Video proyecciones:</b> Victor Vaquero González de Quevedo y <b>Video documental:</b> Israel J. Espino', '<b>Artistas:</b> Clara Ferrao Diz, Alvaro Murillo y Stefano Fabris', '<b>Trainning actoral:</b> Charo Feria', '<b>Dirección coreográfica:</b> Ángeles Vázquez', '<b>Escenografía y Luces:</b> Juan Antonio Moreno', '<b>Diseño de vestuario:</b> Ángeles Vázquez','<b>Fotografía:</b> Jorge Armestar', '<b>Producción:</b>  Entrearte Al-Badulaque S.L. y <b>Co-producción:</b> Consejería de Cultura, Turismo y Deportes, Junta de Extremadura.'],
        synopsisShort:'Espectáculo transversal que profundiza en el Romance de la Serrana de la Vera más allá del aspecto literario que todos conocemos. En 2022, Serrana ha supuesto la candidatura de su protagonista femenina, Clara Ferrao, como mejor actriz de danza en los Premios Max. Ha participado en las Ferias de Teatro de Almagro, Castilla y León o FiraB cosechando una gran acogida en todas ellas. Serrana ha representado a Extremadura en el pabellón de España en la Expo de Dubai.',
        synopsis:'<p>Espectáculo transversal que profundiza en el Romance de la Serrana de la Vera tanto a nivel antropológico como existencial. </p> <p> La dramaturgia sostenida por tres personajes transcurre paralela al romance del siglo XV. Romance que declama el propio pueblo durante la representación. La historia bien podía ser la de una Serrana del siglo XXI. </p> <p>Sin texto, basado en un trabajo coreográfico y de teatro físico, el espectáculo transita por la identidad cultural de un pueblo, por su memoria y por su creación colectiva del mito, con un punto de partida fundamental: la reflexión de Caro Baroja “Un pueblo difícilmente olvida a sus dioses”. El espectáculo comienza con mostrando el equilibrio natural del medio, relacionando cada personaje a un elemento de la naturaleza: aire, agua y tierra. De esta forma se adentra y profundiza en la transformación que el ser humano padece a través de las relaciones de poder, abuso y conformidad.</p> <p> Serrana es un espectáculo con sabor a tierra, teñido de rojo cereza. </p>',
        bannerImage: bannerImageAlbadulake2,
        dossierFile: dossierAlbadulake2,
        affiche: afficheAlbadulake2,
        companyID: 'albadulake',
        trailerURL: 'https://www.youtube.com/embed/Zab1sGL83sg',
        playId:"serrana",
        afficheOrientation: "vertical"
        // released: true
    },
    //{
    //    Title: 'DIKTAT',
    //    credits: ['<b>Dramaturgia y Dirección:</b> Juan Pastor, <b>Ayudante de dirección:</b> Gemma Martínez','<b>Traducción:</b> Fernando Gómez Grande','<b>Música:</b> Luis Ivars','<b>Artistas:</b> Morgan Blasco y Toni Misó','<b>Espacio escénico y vestuario:</b> Joan Sabas','<b>Diseño de iluminación:</b> Mingo Albir','<b>Imagen y comunicación:</b> María Cortés', '<b>Producción ejecutiva:</b> Marylène Albentosa'],
    //    synopsisShort: 'Diktat habla de la aceptación del otro desde una situación extrema. Diktat es la puesta en escena de uno de los autores franceses contemporáneos más respetados y representados en todo el mundo, Enzo Cormann. La obra está hoy más de actualidad que nunca pues aunque ambientada en un conflicto imaginario toca temas como el perdón la corrupción o la ternura suponiendo una emocionante y bella reflexión sobre la civilización que nos une tanto como nos distancia.',
    //    synopsis:'<p>Dos hermanastros de distintos padres se enfrentan con sus razones y vivencias pasadas en un desencuentro lleno de intrigas e intereses políticos. El hermano mayor es tracio y los suyos gobiernan el país, después de haber expulsado a los triballos con los que resultó imposible la convivencia. El hermano menor, triballo, vuelve de su exilio después de veinticinco años y secuestra a su hermano mayor que está a punto de entrar en el nuevo gobierno tracio como ministro de sanidad. Para su liberación exige ciertas condiciones que no llegan a cumplirse. La amenaza es la muerte.</p> <p>En el enfrentamiento que se producirá en el lugar del secuestro descubrimos las terribles realidades de lo que fue la guerra y las razones encontradas de ambos. En el fondo vemos a dos hermanos de infancia feliz, que intentan desesperadamente comunicarse y explicarse más allá de las palabras, buscando un espacio donde encontrarse. Posiblemente al final lo consigan en el lugar que ocupa una canción infantil.</p> <p>Como telón de fondo, una guerra fraticida. Un nacionalismo excluyente que impide la convivencia. Este huracán arrasa países, aniquila comunidades, expulsa minorías étnicas o destruye al que considera enemigo interno. Cualquier enfrentamiento civil, azuzado por la manipulación del nacionalismo excluyente, puede terminar con el terror genocida. Puede parecer una circunstancia lejana para países que se creen civilizados, pero la historia nos demuestra lo contrario.</p>',
    //    bannerImage: bannerImageColl1,
    //    dossierFile: dossierColl1,
    //    affiche: afficheColl1,
    //    companyID: 'collectiuIntermitent',
    //    trailerURL: 'https://www.youtube.com/embed/rCXOpCQIlTY',
    //    released: true,
    //    playId:"diktat",
    //    afficheOrientation: "vertical"
    //},
    //{
    //    Title: 'ZERO 16',
    //    credits:['<b>Texto:</b> Fulgencio M. Lax','<b>Dirección:</b> Morgan Blasco','<b>Artistas:</b> Xiomara Wan Den y Morgan Blasco','<b>Diseño de iluminación:</b> Mingo Albir', '<b>Espacio Sonoro y música:</b> Alicia Bernal y Leinad Rodiger', '<b>Espacio escénico:</b> Joan Sabas', '<b>Diseño de vestuario:</b> Joanmi Reig', '<b>Imagen y comunicación:</b> María Cortés'],
    //    synopsisShort:'Este proyecto nace a partir de un texto de Fulgencio M. Lax que destaca en primer término la lacra social que viven las mujeres a lo largo de la historia: la violencia de género. En esta obra, se le da voz y cuerpo a ELLA en mujeres que abordan el conflicto por medio de un lenguaje diferente. Cada una, representa una capa más profunda de la víctima, una voz interna que la empuja a recuperar su dignidad como mujer.',
    //    synopsis:'<p>Zero 16 es un espectáculo multidisciplinar y atractivo, especialmente destinado a una franja de público joven a la que sensibilizar en la temática que trata la obra, mientras descubren y comprenden el teatro como un medio de expresión de los conflictos que afectan a la sociedad en la que vivimos. La función social del teatro a la que se refiere Fulgencio Martínez Lax en numerosas ocasiones. <br><br> ELLA es una mujer que ha sufrido el sometimiento machista de ÉL. Ahora decide abandonar a su pareja pero este no se lo pondrá fácil. En el umbral de su escapada y con una pequeña maleta en la mano, hace memoria de la historia de su matrimonio, de cómo apareció ÉL en su vida, de cómo fueron los inicios, de cuándo y cómo empezó la decadencia de la relación. Y no puede evitar sentirse culpable o buscar los motivos que hicieron que ÉL cambiara de esa manera. Su voz interna es la que le grita que no es así, que ella no fue responsable de ese cambio en ÉL, que ÉL ya era así aunque ella no quisiera verlo. Su voz interna es la que todavía quiere bailar, quiere marcharse, quiere vivir. Y es en ese espacio donde encuentra el valor para decir, ¡basta!</p>',
    //    bannerImage: bannerImageColl2,
    //    dossierFile: dossierColl2,
    //    affiche: afficheColl2,
    //    companyID: 'collectiuIntermitent',
    //    trailerURL: trailerPlaceholderColl2,
    //    released: true,
    //    playId:"zero_16",
    //    afficheOrientation: "vertical"
    //},
    
    //{
    //    Title: 'Los ruidos de la noche',
    //    credits:['<b>Autora, Dramaturgia, Dirección y Diseño de escenografía:</b> Ángeles Jiménez Soria','<b>Actrices:</b> Ana Morán y Sheila Montes, <b>Realización:</b>Nuria Trabanco','<b>Música original:</b> Mar Álvarez','<b>Ilustraciones originales:</b> Pablo Prestifilippo', '<b>Diseño Gráfico:</b> Juan Hernaz, <b>Vídeo:</b> Sr Paraguas','<b>Diseño de iluminación:</b> Gonzalo Mateos, Xabi Alonso y Adrián Cavero','<b>Técnicos:</b> Gonzalo Mateos y Alquisón iluminación y sonido','<b>Dirección de producción:</b> Carmen Gallo, <b>Producción:</b> Factoría Norte'],
    //    synopsisShort:'Los ruidos de la noche es un espectáculo que surge de un cuento del mismo nombre, que ha tenido más de diez ediciones y que fue recomendado por el departamento de cultura y educación de la Generalitat de Cataluña habiendo servido de base para trabajar en diferentes proyectos educativos. Adaptación muy gestual y corporal por parte de las intérpretes y canciones compuestas por Mar Álvarez que tienen una fabulosa acogida por parte de los pequeños espectadores.',
    //    synopsis:'<p>Cuando abrimos una ventana estamos dispuestos a que, a través de ella, entre el mundo en nuestra vida, entren en ella personas y sentimientos que nos la van a transformar. Mi casa, mi vida, está llena de ventanas: unas son cuadradas, triangulares, redondas… geométricas… y a través de ellas acceden a mi vida las cosas cotidianas. Pero tengo otras ventanas con forma de luna y por éstas entra en mí lo poético, lo impredecible, lo mágico.</p><p>Cuando Catalina abre una ventana hace que aparezca en su vida una persona que la va a modificar para siempre: ¿qué tipo de sentimientos le va a provocar? ¿Por qué se mueve entre el odio y el amor? ¿Por qué aparece entre ellas el monstruo de los celos?</p> <p>Mediante los enfrentamientos que van construyendo a lo largo de la historia, veremos lo que las une y lo que las separa y, sobre todo, cómo se complementan y cómo se hacen necesarias para superar sus miedos. La noche hace florecer nuestros temores y saca a flote nuestras pesadillas pero si estamos acompañados, no hay miedo al que no podamos vencer.</p> <p> Los ruidos de la noche, es un espectáculo que surge de un cuento que resulta ideal para servir de base a esta nueva dramaturgia.</p>',
    //    bannerImage: bannerImageFN1,
    //    dossierFile: dossierFN1,
    //    affiche: afficheFN1,
    //    companyID: 'factoriaNorte',
    //    trailerURL: trailerPlaceholderFN1,
    //    released: false,
    //    playId:"los_ruidos_de_la_noche",
    //    afficheOrientation: "vertical"
    //},
 //   {
 //       Title: 'Las piripiripiratas',
    //       credits:['<b>Dirección:</b> Eladio de Pablo y Carmen Gallo', '<b>Intérpretes:</b> Sheila Montes y Paula Mata, <b>Canciones:</b> Mar Álvarez', '<b>Escenografía, vestuario y atrezzo:</b> Nuria Trabanco', '<b>Ilustraciones animadas y diseño gráfico:</b> Juan Hernaz, Vídeo: Sr. Paraguas','<b>Iluminación:</b> Gonzalo Mateos','<b>Sonido e imagen:</b> Adrián Cavero y Alquisón iluminación y sonido','<b>Técnico de compañía:</b> Gonzalo Mateos','<b>Producción:</b> Carmen Gallo'],
    //    synopsisShort:'Las piripiripiratas, con parches y a lo loco y con faldas, que no es poco, es una loca aventura dinámica con canciones originales, situaciones hilarantes y mucha comicidad. Si bien la función está planteada para aun público familiar, es perfectamente disfrutable por cualquier edad. Son más habituales las historias de piratas masculinos pero, aquí se aprovecha para poner en valor el mundo de la mujer, el valor y el respeto a la naturaleza y la lucha contra los mares de plástico.',
    //    synopsis:'<p>Una capitana aventurera y su intrépida grumete, las piripiripiratas Bogavanta y Anchoveta, inician un viaje en busca del tesoro. Pero no es un tesoro cualquiera, no. ¿Qué será? El mar es para ellas la ventana a un mundo nuevo, donde todo es posible, pero en su camino tendrán que resolver enigmas, vencer al monstruo de los mares... ¿queréis viajar con ellas? </p><p>Las piripiripiratas, con parches y a lo loco y con faldas, que no es poco es una loca aventura dinámica, con canciones originales, situaciones hilarantes y sobre todo mucha comicidad: tiene humor, guiños a los más adultos, canciones en directo, sorpresas…  Si bien la función está planteada para un público familiar a partir de 6 años, es perfectamente disfrutable por niñas, niños y adultos de cualquier edad. Se caracteriza por usar un humor universal  que no entiende de edades, como los equívocos y el propio carácter de los personajes que los llevan a situaciones cómicas para todo el mundo. </p><p>Es un espectáculo con una escenografía sencilla pero resolutiva que aborda los dos espacios esenciales de la obra: el barco y la isla. Así mismo, luce un vestuario sencillo pero eficaz en llamar la atención del espectador. Factoría Norte, aprovecha esta trepidante aventura para poner en valor el mundo de la mujer dado que no son habituales las historias de piratas femeninas.</p>',
    //    bannerImage: bannerImageFN2,
    //    dossierFile: dossierFN2,
    //    affiche: afficheFN2,
    //    companyID: 'factoriaNorte',
    //    trailerURL: ' https://www.youtube.com/embed/EzUWpNvrxqM',
    //    released: true,
    //    playId:"las_piripiripiratas",
//    afficheOrientation: "vertical"
//    },
    {
        Title: 'Con M mayúscula',
        credits:['<b>Dramaturgia:</b> Luisa Aguilar y Luis Vigil, <b>Dirección:</b> Luis Vigil', '<b>Artistas:</b> Ici Díaz y Enrique Dueñas', '<b>Producción y ayudante de dirección:</b> Marta Vicente, <b>Asesora figurinismo:</b> Manuel Caso', '<b>Composición musical y espacio sonoro:</b> Verónica R. Galán', '<b>Espacio escénico e iluminación:</b> Luis Vigil','<b>Diseño cartelería:</b> Manu González','<b>Colaboración construcción objetos:</b> Nacio Quirós y Celsa Granda','<b>Fotografía:</b> Sergio Álvarez Flórez y Víctor E. Alonso'],
        synopsisShort:'Con M mayúscula, es un proyecto realizado en el marco del Centro de Recursos Escénicos del Principado de Asturias. Ha obtenido el premio OH! a Mejor Espetáculo para la Infancia, Mejor Diseño de Iluminación, Mejor Composición Original y Mejor Indumentaria. Con M mayúscula, está inspirada en el texto de Adela Turín, Arturo y Clementina, un cuento para hablar de igualdad y construir un mundo mejor, más igualitario, libre de estereotipos sexistas y discriminación.',
        synopsis:'<p>Dos tortugas se enamoran y deciden compartir su vida. Pero mientras Clementina es inquieta y valora la cultura y el arte, Arturo cercena cualquier atisbo de su compañera y se ríe de su "ingenuidad" al querer desarrollar distintas actividades artísticas.</p><p>Con una puesta en escena sencilla y sutil, con M mayúscula muestra una de las formas de violencia que más daño hacen entre las parejas: aquella que utiliza la descalificación y se burla de las inquietudes del otro minando la autoestima del que es agredido. De esta forma, cada vez que Arturo encontraba ridícula a Clementina con sus preguntas, sueños y aspiraciones, esta se encontraba a su vez tonta y aburrida, procurando no mostrarse ante él como realmente es lo cual es una manera de ser coartada. Que el espectador vea la infelicidad de Clementina por verse obligada a callar por miedo a no decir lo apropiado, a llevar una vida que le hace  infeliz, permite reflexionar sobre la necesidad de conocernos y respetarnos a nosotros mismos para luego poder encontrar quien nos complemente sin anularnos jamás.  ¿Qué pensarán los niños de la pobre Clementina? ¿Se atreven a ser como son delante de sus amigos?</p>',
        bannerImage: bannerImageKamante,
        dossierFile: dossierKamante,
        affiche: afficheKamante,
        companyID: 'kamanteTeatro',
        trailerURL: 'https://www.youtube.com/embed/Idv1aEPv9RE',
        released: true,
        playId:"con_m_mayuscula",
        afficheOrientation: "horizontal"
    },
    {
        Title: 'Katia the Cat',
        credits:['<b>Idea original:</b> Sué y Nilo Londoño Trueba', '<b>Dramaturgia:</b> Patricia Rodríguez', '<b>Dirección de escena:</b> Javier Ariza', '<b>Intérpretes:</b> Alicia Trueba y Xandra Gutiérrez', '<b>Composición Musical:</b> Cristian Londoño','<b>Diseño de Vestuario:</b> Ikerne Giménez, <b>Modista:</b> Wendy Parthoens', '<b>Diseño de iluminación:</b> Flavia Mayans', '<b>Escenografía:</b> Ikerne Giménez, <b>Fotografía:</b> Áureo Gómez','<b>Producción:</b> Café de las Artes Teatro'],
        synopsisShort:' Katia the cat, es una comedia familiar que nace de la imaginación de Sué y Nilo Londoño Trueba, una niña y un niño que han sido capaces de inventar todo un universo gato que Café de las Artes Teatro ha sabido hacer realidad con una divertida puesta en escena y toques de clown y magia. Se trata de un espectáculo inspirado en el mundo del comic, con toques de musical y con una banda sonora compuesta por Cristian Londoño especialmente para la ocasión.',
        synopsis:'<p>Katia the Cat es una gata, investigadora privada que vive en el Mundo Gato, en la ciudad de Catburgo. Una mañana, la ciudad se despierta conmocionada por la desaparición de las “Gemas de Poder” del Templo de las diosas gato Panterian y Florai. Fofín, el recogebasuras de la ciudad, afirma haber visto a dos ratones huyendo con el botín al anochecer… ¿Estará la maléfica Dra. Chigüiro detrás de todo esto? Con la ayuda de su fiel ayudante Ramón Pumita, Katia the Cat deberá embarcarse en una trepidante aventura para salvar el Planeta Morgan<br><br>Katia the Cat, es un espectáculo de pequeño formato en el que aparecen 9 personajes interpretados por Alicia Trueba y Xandra Gutiérrez bajo la dirección de Javier Ariza. Se trata de una obra pensada para poder ser representada tanto en teatros como en salas de bibliotecas, colegios u otros espacios no convencionales Utiliza el texto con diálogos chispeantes, la coreografía, la acrobacia, la manipulación de objetos, la magia y las canciones cantadas en directo para hacer cómplice a los pequeños espectadores de todo lo que ocurre en escena.<br><br>Los lenguajes utilizados son: el teatro de texto, la coreografía, la acrobacia, la manipulación de objetos, la magia y la música con composiciones originales creadas por Cristian Londoño y cantadas por Alicia Trueba y Xandra Gutiérrez.</p>',
        //bannerImage: bannerImageArtes2,
        dossierFile: 'https://drive.google.com/file/d/1XDSwPI4kI8p10SLIPXugAogyOl12U292/view?usp=share_link/Dossier Katia the Cat - 2022 (Arrasa)',
        affiche: afficheArtes3,
        companyID: 'artesTeatro',
        trailerURL: 'https://www.youtube.com/embed/OmJg0KqhJKo',
        released: true,
        playId:"katia_the_cat",
        afficheOrientation: "vertical"
    },
    {
        Title: 'Las mariposas de París',
        credits:['<b>Historia original y Concepción del espectáculo:</b> Alicia Trueba','<b>Creación:</b> Alicia Trueba, Cristian Londoño y Florencia Ávila','<b>Interpretación y manipulación de muñecos:</b> Alicia Trueba y Cristian Londoño','<b>Escenografía y fabricación de muñecos:</b> Cristian Londoño, Alicia Trueba y Florencia Ávila con la colaboración de Ana Abascal y Consuelo Toca','<b>Vestuario:</b> Alicia Trueba y Consuelo Toca, <b>Composición musical:</b> Cristian Londoño','<b>Iluminación:</b> Flavia Mayans con la colaboración de Eusebio Balza','<b>Diseño gráfico:</b> Raquel Sánchez, <b>Producción:</b> Café de las Artes Teatro.'],
        synopsisShort:'Las mariposas de París es una obra familiar para niños a partir de 4 años. El espectáculo incluye sombras chinas, teatro de objetos, minuciosas escenografías, cajas de música, muñecos y marionetas. Las Mariposas de París ha sido escogida para el circuito de la Red de Teatros alternativos 2016, circuito de la Red de Teatros de la Comunidad de Madrid 2016-2017 y para el circuito de la Red de Teatro de Navarra 2017.',
        synopsis:'<p>Una historia acerca de una época, acerca de un lugar pero sobre todo una historia acerca del amor... Un hombre está caminando por un cable a 300m de altura hacia la torre Eiffel. La muchedumbre sin aliento se amontona y murmura ante cada uno de sus pasos... Pero... Quién es ese hombre?</p> <p>París, 1892, en la ciudad de grandes artistas y grandes inventos que hacían maravillar al mundo, una joven estrella del famoso cabaret Folies Bergére y un deshollinador están a punto de conocerse, un encuentro que cambiará sus vidas.</p> <p>Café de las Artes Teatro sigue apostando por nuevas formas de contar historias, investigamos nuevos lenguajes, basándonos en disciplinas artísticas tradicionales pero dándoles una nueva visión y fusionándolas. Las Mariposas de París se nutre de un lenguaje visual, de narración con imágenes, música, objetos, muñecos, marionetas y sombras chinas. Un lenguaje universal que permite que la obra y su mensaje llegue a cualquier persona sea cual sea su edad. Su argumento, quiere aportar a los niños una visión más amplia de los posibles desenlaces de la vida, con el fin de ayudarles a asumir con más naturalidad aspectos de esta como el fracaso o, la muerte. Las Mariposas de París, ofrece una visión poética de la muerte a través de la metáfora, entendiendo ésta como una transformación.',
        bannerImage: bannerImageArtes1,
        dossierFile: dossierArtes1,
        affiche: afficheArtes1,
        companyID: 'artesTeatro',
        trailerURL: 'https://player.vimeo.com/video/118330029?h=1f6c22e623',
        released: true,
        playId:"las_mariposas_de_paris",
        afficheOrientation: "vertical"
    },
    {
        Title: 'El inolvidable viaje de Nana',
        credits:['<b>Concepción, dirección e interpretación:</b> Alicia Trueba Toca', '<b>Asesoramiento puesta en escena:</b> Lucrezia M. y Cristian Londoño', '<b>Coreografía:</b> Lucrezia Maimone', '<b>Diseño de Escenografía y Atrezzo:</b> Café de las Artes Teatro', '<b>Realización Marionetas:</b> Alicia Trueba', '<b>Realización Vestuario:</b> Alicia Trueba','<b>Diseño de iluminación:</b> Flavia Mayans de la Morena','<b>Ilustraciones:</b> Lucrezia Maimone, <b>Composición Musical:</b> Cristian Londoño', '<b>Creación audiovisual:</b> Yann Loïc Lambert, <b>Producción:</b> Café de las Artes Teatro.'],
        synopsisShort:' El inolvidable viaje de Nana, es una historia onírica que muestra en 50 minutos de recorrido, la magia de la maternidad. Se trata de un viaje en globo acompañado de vistosas proyecciones sobre un aro acrobático con el que interactúa la protagonista, Esta obra, se nutre de la complicidad con los niños que se comunican en todo momento con la protagonista a través de los gestos, los sonidos, la música y la danza mezclada con acrobacias.',
        synopsis:'<p>Nana está embarazada y poco a poco descubre como su cuerpo comienza a cambiar para ya nunca volver a ser uno, sino dos. Nana tiene la sensación de haber subido a un globo aerostático y caminar por el aire durante nueve meses. Y más. Su bebé nacerá, se hará niño, joven adulto y padre.</p> <p>El inolvidable viaje de Nana, un viaje en globo que sobrevuela el terreno insondable del ciclo de la vida. El inolvidable viaje de Nana, es una colección de recuerdos, trocitos de una vida, lleno de sorpresas y sueños cumplidos. Es un espectáculo visual sencillo, elegante y sorprendente destinado a público familiar y niños a partir de 2 años. Se trata de una historia cercana porque cada espectador la ha vivido, o la va a vivir, marcada por el sello de sus circunstancias, de sus experiencias. Una historia contada con la delicadeza, elegancia y sensibilidad con la que Café de las Artes Teatro (CdAT) afronta todos sus proyectos, con un marcado sello educativo y una puesta en escena didáctica y participativa gracias a la cual, los niños no son meros espectadores.</p>',
        bannerImage: bannerImageArtes2,
        dossierFile: dossierArtes2,
        affiche: afficheArtes2,
        companyID: 'artesTeatro',
        trailerURL: 'https://www.youtube.com/embed/dJuQXHETmKQ',
        released: true,
        playId:"el_inolvidable_viaje_de_Nana",
        afficheOrientation: "vertical"
    },
//    {
//        Title: 'No pleasure',
//           credits:['<b>Dirección artística y coreográfica:</b> Iker Karrera','<b>Ojo externo:</b> Fernando Lázaro', '<b>Diseño de escenografía:</b> Matías Zanotti', '<b>Diseño de iluminación:</b> Alberto D. Centeno A.E.C.', '<b>Diseño de vestuario:</b> Laura Cortés', '<b>Intérpretes:</b> Pablo Costas, Carla Diego, Belén Leroux, César Luzán, Raymond Naval y Yadira Rodríguez.'],
//        synopsisShort:'No Pleasure, se estrenó en 2020, en Madrid, dentro de la programación del II Ciclo de Danza Mover Madrid, en la sala Cuarta Pared. Tras agotar todas las entradas de sus tres funciones en, tan sólo, 48 horas, se programó una cuarta función extra donde colgaron también el cartel de todo vendido en tiempo record. La acogida por parte del público fue brutal y la compañía está impaciente por seguir representando la pieza para continuar conectando y compartiendo esta fantástica experiencia.',
//        synopsis: '<p>La anhedonia es la incapacidad de experimentar placer, la pérdida de interés y disfrute en todas las actividades que alguna vez te gustaron. La sensación de que ya nada te importa ni te motiva. ¿Qué hacer cuando no sientes nada? </p> <p>Partiendo de esta idea, el coreógrafo vasco Iker Karrera, crea No Pleasure. Como ya viene siendo habitual en sus trabajos, el protagonismo reside en su propio lenguaje coreográfico, basado en la fusión de diferentes géneros de danza. Una coreografía que apuesta por entretener, abrir nuevos caminos y conectar con nuevos públicos. Esta pieza, más que de los límites del placer, pareced hablar de sus limitaciones, de la imposibilidad de encontrar verdadera satisfacción en lo que socialmente ha sido declarado divertido… </p> <p>Acompañando este espectáculo, la compañía de Iker Karrera ofrece la posibilidad de impartir talleres de diferentes estilos de danza con todos sus integrantes. De esta forma, un día antes de la representación pueden llevarse a cabo con el fin de animar y motivar la asistencia al espectáculo del día siguiente. Gracias a su paso por el programa Fama ¡a bailar! emitido recientemente en Movistar+, estos talleres suelen ser un muy buen reclamo y una oportunidad para conocer a la compañía más de cerca.</p>',
//        bannerImage: bannerImageArtes2, // cambiar este
//        dossierFile: dossierIker, // cambiar este
//        affiche: afficheIker, // cambiar este
//        companyID: 'ikerKarrera',
//        trailerURL: 'https://www.youtube.com/embed/c48F9-B8TlQ',
//        released: true,
//        playId:"no_pleasure",
//        afficheOrientation: "horizontal"
//    },
    {
        Title: 'The Room Where It Happens',
        credits:['<b>Dirección artística y coreográfica:</b> Iker Karrera','<b>intérpretes:</b> Katalin Arana, Marc López, Raymond Naval, Serena Pomer, Teresa Royo / Carla Diego', '<b>Música original:</b> Alex Aller', '<b>Ayudante de coreografría:</b> Carla Diego', '<b>Diseño de iluminación:</b> Rodrigo Ortega', '<b>Diseño de vestuario:</b> Laura Cortés', '<b>Dramaturgia:</b> Verónica Ronda y David Serrano', '<b>Producción:</b> Isabella Lima', '<b>Distribución:</b> ARRASA Producción y Distribución'],
        synopsisShort:'El horror vacui del siglo XXI habla del miedo al vacío, de vaciar de contenido nuestro tiempo. Estar ocupado es el remedio perfecto para no pensar. Repetir una larga rutina de distracciones que atiborran el alma para así esquivar quedarse a solas con uno mismo.\n' +
            '¿Qué ocurre cuando uno decide parar y se encuentra de golpe, frente a frente, con esa habitación a puerta cerrada donde todo sucede? ¿Qué se esconde ahí dentro?',
        synopsis: '<p>El horror vacui del siglo XXI habla del miedo al vacío, de vaciar de contenido nuestro tiempo.' +
            '</p><p> Estar ocupado es el remedio perfecto para no pensar. Repetir una larga rutina de distracciones que atiborran el alma para así esquivar quedarse a solas con uno mismo.\n' +
            '¿Qué ocurre cuando uno decide parar y se encuentra de golpe, frente a frente, con esa habitación a puerta cerrada donde todo sucede? ¿Qué se esconde ahí dentro?\n' +
            '</p><p>Partiendo de esta idea, el creador vasco Iker Karrera, crea su nueva pieza The Room Where It Happens. Como ya es habitual en sus trabajos, el protagonismo reside en su propio lenguaje coreográfico basado en la fusión de diferentes géneros de danza que apuesta por entretener, abrir nuevos caminos y conectar con nuevos públicos. ' +
            '</p><p>The Room Where It Happens es una pieza autoral, en apariencia muy alejada de las verdaderamente festivas que este polifacético creador monta por encargo para musicales, destacando su participación como coreógrafo en producciones como Mamma mía! o The Book of Mormon, que siguen representándose en los teatros de la Gran Vía madrileña. Y es que Iker Karrera, aunque siempre desde la danza, se mueve en territorios muy distintos del espectro artístico.</p>',
        //bannerImage: bannerImageArtes2,
        dossierFile: 'https://drive.google.com/file/d/1K1WxZfo0IvHJf5tQL0Yp2xCa5cY8cNSV/view?usp=drive_link',
        affiche: afficheIker,
        companyID: 'ikerKarrera',
        trailerURL: 'https://www.youtube.com/embed/aQvsREQkRVU',
        released: true,
        playId:"the_room",
        afficheOrientation: "horizontal"
    },
    {
        Title: 'Alfonso X. La última Cantiga',
        credits:['<b>Idea original:</b> Jesús Lozano', '<b>Dramaturgia:</b> Jesús Lozano', '<b>Dirección de escena:</b> Jesús Lozano, <b>Ayudante de dirección:</b> Jesús Peña', '<b>Intérpretes:</b> Jesús Lozano, Inma Cedeño, Enrique Pastor, Marina Haberkorn, Pablo Cantalapiedra y María José Pire','<b>Dirección Musical:</b> Enrique Pastor, <b>Sonidista:</b> Samuel Cotilla', '<b>Diseño de Vestuario:</b> María de Melo Collection,<b> Modista:</b> Laura Álvarez Miravete','<b> Diseño de iluminación:</b> Samuel Cotilla','<b> Escenografía:</b> Carpintería Píriz Navarro,<b> Fotografía:</b> José Bayón', '<b>Producción:</b> María de Melo Producciones y La Diosa Cómica.'],
        synopsisShort:'Alfonso X, la última cantiga, es la expresión apasionada y desesperada del último aliento de este rey sabio que abandona las aulas y los libros de historia para mostrarse a nosotros, a través del teatro aderezado con música en directo. Cuando se cumplen 800 años del nacimiento de Alfonso X, El Sabio, esta obra de teatro brillantemente escrita y magníficamente interpretada por Jesús Lozano es la mejor manera de reencontrarse con una de esas dramatizaciones que no son frecuentes de encontrar.',
        synopsis: '<p>Alfonso X, la última cantiga es una obra de teatro de poderosa fuerza dramática y sutileza musical que emociona y trasciende al público que vive una reflexión continua con el presente siguiendo al protagonista en su búsqueda por desvelar la naturaleza y la condición humana, algo que sigue siendo una inquietud hoy en día.<br><br>Siete cuadros, siete escenas acompañadas musicalmente por siete cantigas, componen una travesía a través de los recuerdos prometedores del rey sabio hacia un presente desolador: sus ambiciones truncadas, las constantes insurrecciones de la nobleza, su dolor ante la prematura muerte de su primogénito y la posterior guerra civil con Sancho, su segundo hijo, el abandono y traición de su esposa Violante, las invasiones francesas al norte y de berimerines al sur y, por si todo esto fuera poco, el doloroso sufrimiento físico provocado por una grave enfermedad que condicionó su carácter.<br><br>Alfonso X, la última cantiga, es la expresión apasionada y desesperada del último aliento de este rey sabio que abandona las aulas y los libros de historia para mostrarse a nosotros, a través del teatro aderezado con música en directo. Cuando se cumplen 800 años del nacimiento de Alfonso X, El Sabio, esta obra de teatro brillantemente escrita y magníficamente interpretada por Jesús Lozano es la mejor manera de reencontrarse con una de esas dramatizaciones que no son frecuentes de encontrar. </p>',
        //bannerImage: bannerImageArtes2, 
        dossierFile: 'https://drive.google.com/file/d/11Wkdlg4dPl4SNNt3tRaDY2bJHrz6WBAI/view?usp=share_link',
        affiche: afficheAlfonsoX,
        companyID: 'MariaMelo',
        trailerURL: 'https://www.youtube.com/embed/iOFr4IlGTjM',
        released: true,
        playId:"Alfonso",
        afficheOrientation: "vertical"
    },
    {
        Title: 'Nada Ni Nadie',
        credits:['<b>Texto:</b> Jesús Lozano Dorado', '<b>Dirección de escena:</b> Jesús Peña (Teatro Corsario)', '<b>Intérpretes:</b> Jesús Lozano y José Antonio Lucia','<b>Diseño de iluminación:</b> Luis Perdiguero', '<b>Asistentes de iluminación:</b> Juan José Hernández Triguero y Nuria Prieto', '<b>Espacio sonoro:</b> Álvaro Rodríguez Barroso', '<b>Dirección de arte y vestuario:</b> María de Melo Collection','<b>Escenografía:</b> Talleres El Molino', '<b>Fotografía:</b> José Bayón', '<b>Vídeo:</b> Visto y no Visto Producciones', '<b> Producción:</b> María de Melo Producciones'],
        synopsisShort:'Un paisaje post-apocalíptico. Dos hombres en la soledad de un mundo destruido. Un texto corrosivo, inteligente, ácido y reflexivo. Una distopía donde el juego entre los dos personajes representa los anhelos, pensamientos y dudas de la humanidad. Una reflexión sobre el poder, la finitud humana y los diversos disfraces que adoptamos para enfrentarnos a la nada. Todo ello aderezado con pasajes de pura comedia, aunque de entrada pueda parecer imposible…',
        synopsis: '<p>Si tuvieras todo el poder… ¿cuál sería tu modo de conducirte, tus normas morales? <br><br>Si fueras dueño de todo lo que queda, de lo que resta, ¿serían tus intenciones, tus acciones diferentes de las que en otro tiempo fueron ostentadas por otros? <br><br>Mat y Pit son dos personajes que representan metafóricamente lo más crudo de la cruda sociedad en la que vivimos pendiente siempre de una espada de Damocles sobre nuestras cabezas. ¿Somos lo suficientemente maduros, inteligentes para discernir lo verdaderamente importante de lo superfluo?, ¿existe alguna esperanza de elevarnos sobre nuestros ombligos y de enfrentar los problemas serios de la existencia y de no escondernos con actitudes pueriles detrás de lo inane? ¿Puede el humor, incluso en los tiempos más duros, salvarnos al fin y al cabo de la miseria que arrastramos? <br><br>Nada ni nadie, tragicomedia del poder en el fin de los tiempos, es una aproximación distópica a lo que podría ser la vida en sus últimos latidos, ese juego finito de seres de alma infinita abandonados en un pálido punto azul del universo. </p>',
        //bannerImage: bannerImageArtes2,
        dossierFile: 'https://drive.google.com/file/d/1-KPAx2jFU30CenS2aoOEL1TNDKo4x5Q0/view?usp=share_link',
        affiche: afficheNadaNiNadie,
        companyID: 'MariaMelo',
        trailerURL: 'https://www.youtube.com/embed/RgOIXZwQHKo?si=baiq6Dlc7il-Z8T0',
        //trailerURL: trailerPlaceholderNadaNiNadie,
        released: true,
        playId:"nada_ni_nadie",
        afficheOrientation: "vertical"
    },
    {
        Title: 'Un puente al más allá',
        credits:['<b>Autor y Director:</b> Jesús Lozano Dorado', '<b>Intérpretes:</b> Inma Cedeño y Jesús Lozano','<b>Vestuario:</b> María de Melo Collection', '<b>Escenografía:</b> María de Melo Collection', '<b>Producción:</b> María de Melo Producciones'],
        synopsisShort:'Comedia escrita y dirigida por Jesús Lozano, basada en una idea de Conrad Seiler. Reírse de nuestras miserias es la forma más divertida de enfrentarlas. Este es el mayor propósito de esta obra. Waldo y Julia, dos personajes en la deriva de sus vidas que, sin embargo, a las puertas del abismo se enredan en una comedia desternillante en la que desnudan sus verdaderos deseos y pasiones, desgranándolos en un diálogo continuo, deshilando los problemas actuales de nuestra sociedad hasta convertirlos en piezas cómicas.',
        synopsis: '<p>Reírse de nuestras miserias es la forma más divertida de enfrentarlas. Este es el mayor propósito de esta obra. Waldo y Julia, dos personajes en la deriva de sus vidas que, sin embargo, a las puertas del abismo se enredan en una comedia desternillante en la que desnudan sus verdaderos deseos y pasiones, desgranándolos en un diálogo continuo, deshilando los problemas actuales de nuestra sociedad hasta convertirlos en piezas cómicas. <br>Dama y caballero se encuentra a altas horas de una decimonónica noche primaveral en un puente iluminado por la tenue luz de una farola, que súbitamente los sorprende en su intento paralelo de adentrarse en el abismo de las frías aguas de un caudaloso río. ¿Qué les ha conducido a tan fatal destino?<br>En el contexto de la «nueva realidad» que ambos viven y en el transcurso de esta sátira, reflejo y remedo en la distancia de los tiempos inciertos y convulsos que vivimos, se desenredan los temas candentes que acompañan el devenir de la existencia de estas dos almas fugitivas, se desvelan los secretos y las media verdades, los miedos, los deseos y los sueños que sostenían la simétrica asimetría de sus vidas, que por caminos distintos les han llevado a una misma, trágica e irrevocable decisión. ¿Irrevocable…? ¡Cuidado! ¡Alguien se acerca…!<br>Cuando la terca niebla pierde su fuerza, se abren a la vista las centelleantes aguas que baña la media luna embelesada por el lejano canto de las sirenas de los barcos y se distingue la silueta de dos seres perdidos en un inesperado encuentro…, pues no hay nada que el amor no pueda vencer, salvo quizás… al enemigo invisible.</p>',
        //bannerImage: bannerImageArtes2,
        dossierFile: 'https://drive.google.com/file/d/1i3sBLzyqfVFv4PXJIvdcj03v0U0mpj4Y/view?usp=sharing',
        affiche: affichePuenteAlMasAlla,
        companyID: 'MariaMelo',
        trailerURL: 'https://www.youtube.com/embed/c3bO5pDL8u4?si=sKzcMjOk6I6pz9VV',
        released: true,
        playId:"un_puente_al_mas_alla",
        afficheOrientation: "vertical"
    },
    {
        Title: 'El juego de los embustes',
        credits:['<b>Autor y Director:</b> Jesús Lozano Dorado', '<b>Intérpretes:</b> Inma Cedeño, Amparo Morquecho, Pilar Brinquete y Jesús Lozano','<b>Diseño de Arte, Vestuario y Escenografía:</b> María de Melo Collection', '<b>Realización Escenografía:</b> Talleres El Molino', '<b>Diseño Iluminación:</b> Luis Perdiguero', '<b>Ayudante Iluminación:</b> Miguel Salinero', '<b>Coreografía:</b> Ángel Zambrano', '<b>Esgrima Escénica:</b> Iker Alejo', '<b>Música en directo:</b> Emilio Villalba y Sara Marina', '<b>Fotografía:</b> José Bayón', '<b>Producción:</b> María de Melo Producciones'],
        synopsisShort:'Una comedia al más puro estilo clásico donde nadie es quien dice ser y todos quieren ser quienes no son. “El Juego de los Embustes” es una comedia de engaños y de enredos escrita en verso por Jesús Lozano y ambientada en la pompa del siglo XVIII: amores y desamores, esgrima con floretes, bailes y canciones, líos y más líos de Condesas, criadas, lacayos, Señores, libertinas y truhanes que con lujo de detalles dan rienda suelta al engaño, la mentira y la traición. ¡Todo en pos del amor!',
        synopsis: '<p>Un noble con traje como fortuna, pretende a una rica, recta, estricta, esbelta condesa heredera de gran fortuna, platican las plañideras. El caballero se pasa por criado, mayordomo, jardinero, lacayo; se mete en la casa palaciega a escudriñar, a embaucar, a consolar los entresijos de la casa y la condesa; se disfraza de bandido donjuanesco para urdir desde los adentros, cuentos e inventos con los que buscarse el sustento. ¿Es un bribón? Diciendo ser un conde. ¿Es un bufón? Fingiendo un marqués. ¿Es un buscón? ¿Es un ladrón? Y un vizconde de postín con aires de zorro espadachín. Sonsacar quiere los favores y los gustos de la señora condesa a una criada huraña y descarada que dice ser ¡la verdadera condesa! El pícaro perdido, se vuelve a perder cuando otra fina señora libertina y fresca, a la puerta, la condesa dice ser también.<br><br>La casa es una locura, un desgobierno, ¡es un infierno! Son tres condesas que dicen ser, nacer y poder, gobernar y jurar que son la Condesa de Galapagar. El buscón es un don Juan sin rumbo y sin ademán, no sabe quién es quién ni cual es la recompensa por tan vil jugarreta de tres criadas, condesas, hermanas con hacienda y sin reales, truhanes, criados y donjuanes con traje y sin pela.<br><br>Disfrute, no se asuste, es el “Juego de los Embustes”Disfrute, no se asuste, es el “Juego de los Embustes”</p>',
        //bannerImage: bannerImageArtes2,
        dossierFile: 'https://drive.google.com/file/d/1p_Dy6gniPPzIux9lPAaB1ABGuX1vwsx2/view?usp=sharing',
        affiche: afficheJuegoEmbustes,
        companyID: 'MariaMelo',
        trailerURL: 'https://www.youtube.com/embed/pQxTg6ZxfDk?si=lxukIvzL5yR9awIH',
        released: true,
        playId:"el_juego_de_los_embustes",
        afficheOrientation: "vertical"
    },
    //  {
    //    Title: 'El Regalo de Zeus',
    //    credits:['<b>Autoría:</b> Concha Rodríguez', '<b>Dirección:</b> Ángeles Vázquez, Juan Antonio Moreno y Concha Rodríguez', '<b>Reparto:</b> Emma Ozores, Juan Meseguer, Pablo Mejías, Raquel Bravo, Rubén Torres, Sandro Cordero, María José Mangas, Mike Dosperillas, Cira Cabases, Sandra Carrasco, Jorge Safer, Daniel "Sifer" y Daniel Barros','<b>Coreografía:</b> Ángeles Vázquez y Daniel "Sifer", <b>Circo:</b> Cía. Albadulake', '<b>Escenografía:</b> Juan Antonio Moreno, <b>Videomapping:</b> Carlos Lucas','<b>Diseño de vestuario:</b> Ángeles Vázquez, <b>Diseño de iluminación:</b> Rubén Camacho', '<b>Sonidista:</b> Juan Cotallo,<b> Taller de costura:</b> Víctor Manuel López','<b> Maquillaje, peluquería y caracterización:</b> Juanjo Gragera','<b> Efectos especiales y Magia:</b> Alfred Cobami,<b> Fotografía y comunicación:</b> Jorge Amestar', '<b>Producción ejecutiva:</b> Raquel Anaya','<b> Distribución:</b> ARRASA, Producción & Distribución'],
    //    synopsisShort:'’El Regalo de Zeus, es una comedia escrita y dirigida por Concha Rodríguez con la colaboración original e indispensable de la Cía. Albadulake que ha aportado al texto, esas nuevas dramaturgias que conforman su lenguaje habitual: circo, danza e imagen. 13 intérpretes sobre el escenario para envolver al espectador en un universo en el que todo es ficción y verdad al mismo tiempo. <br>Una exhibición de virtuosismo interpretativo, aderezado con magia y efectos especiales, para que todo sea ficción y verdad al mismo tiempo.',
    //    synopsis: '<p>Deucalión sueña con especular en Marte y volver convertido en héroe. Pirra, al igual que su madre Pandora, es exploradora y sueña con escarbar la tierra y rescatar del olvido la historia que nos sustenta. Él sabe que tiene que seguir conquistando. Ella sabe que, en lo más profundo del tiempo y del lejano Olimpo, una vasija guarda la piedra filosofal de la Esperanza y está segura que, rescatándola, relajará a Zeus y su rayo vengativo y descansaremos de todos los castigos que hoy nos acucian.<br><br>Talía, la Musa de la Comedia, baja a la arena a insuflarles su versión de los hechos, consigue subirlos al carro y llevarlos al Olimpo, donde se topan con la magia, el juego, el más difícil todavía, el divertimento, la tensión y todo lo necesario para cambiar el relato; siempre con la ayuda de su madre la Musa de la Memoria; y de su padre, el poderoso Zeus. Melpómene, la Musa de la Tragedia, que atemoriza a la humanidad y siempre cosechando gran éxito, no va a ponerlo fácil. Toda historia, con su prejuicio o veneración, es parte de la imaginación, de la leyenda. <br><br> Todo es ficción y cierto al mismo tiempo. ¡Por Zeus, cuánto bulo! <br>Dejen fuera la incredulidad y disfruten.</p>',
    //    //bannerImage: bannerImageArtes2,
    //    dossierFile: 'https://drive.google.com/file/d/1KtEVu-xYKmAB7y8JpxkA6avK2A18gyTz/view?usp=drive_link',
    //    affiche: afficheElRegaloZeus,
    //    companyID: 'estampa',
    //    trailerURL: 'https://www.youtube.com/embed/72P0Jktf-as?si=t7WadivsX4D2SwFr',
    //    released: true,
    //    playId:"RegaloZeus",
//    afficheOrientation: "horizontal"
//    }
]