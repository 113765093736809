import React, { Component } from 'react';
import './PlayDescriptionModal.css';
import genomaBBanner from'../../../ressources/images/full/playTopBannerGernomaB.PNG'
import cross from'../../../ressources/images/full/x-mark.png'
import back from'../../../ressources/images/full/previous.png'
import dossierGenomaB from '../resources/dossiers/dossierAlbadulake-1.pdf'

class PlayDescriptionModalTrailerLess extends Component {
    render() {
        return(
            <div className="pieceDetails">
                    <div className="TopBannerWrapper" style={{ backgroundImage: `url(${this.props.play.bannerImage})`, backgroundSize: "cover"}}>
                        <div className="TitleWrapper">
                            <h1 style={{color:'#000000'}}>{this.props.play.Title}</h1>
                        </div>
                    </div>

                    <div className="PlayInfoBanner">
                        <div className="PlayDescription">
                            <h1>Créditos</h1>
                            <h2>{this.props.play.credits}</h2>
                            <h1>Sinopsis</h1>
                            <div className="descriptionDetailedPlay" dangerouslySetInnerHTML={{__html: this.props.play.synopsis}}></div>
                            <a className="buttonDownloadDossier" href={this.props.play.dossierFile} download>DESCARGAR DOSSIER DE LA OBRA</a>
                        </div>

                        <div className="PlayVisuals">
                            <div className="TrailerPlaceHolderContainer" style={{ backgroundImage: `url(${this.props.play.trailerURL})`, backgroundSize: "cover"}}></div>
                        </div>
                    </div> 
                </div>
        )
    }
}

export default PlayDescriptionModalTrailerLess