import React, { Component } from 'react';
import EntryBanner from '../../components/EntryBanner/EntryBanner';
import AboutUs from '../../components/AboutUs/AboutUs';
import Team from '../../components/Team/Team';
import OurCompanies from '../../components/OurCompanies/OurCompanies'
import Contact from '../../components/FooterWithContact/FooterWithContact'

class HomePage extends Component {
    render() {
        return(
            <div>
                <EntryBanner />
                <AboutUs />
                <Team />
                <OurCompanies />
            </div>
        )
    }
}

export default HomePage