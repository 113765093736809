import React, { Component } from 'react';
import './FooterWithContact.css'
import emailjs from 'emailjs-com'
import swal from 'sweetalert';
import instagram from './Instagram2.png'
import facebook from './Facebook.png'
import linkedin from './Linkedin3.png'
import logo from './Logo.png'
import logoUE from './SubvencionUE.png'
import mail from './Mailrojo.png'
class FooterWithContact extends Component {

    
    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            message: "",
            files: null
        }

        this.user_id = 'user_ePLeZX00lwBBKhS1pGH2E'
        this.service_id = 'service_hsvadcr'
        this.template_id = 'template_vx7vxrk'

        // This binding is necessary to make `this` work in the callback
        this.sendMail = this.sendMail.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
      }a
    
      handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(name==="files"){
            const fileobj = target.files;
            this.setState({
                [name]: fileobj
            });
        }else{
            this.setState({
                [name]: value
            });
        }
        //console.log(this.state)
      }

      validateFiles(files){
          var totalSize = 0;
          Array.from(files).forEach(file => totalSize += file.size);
          //console.log(totalSize)
          if(totalSize > 500000){
            swal("Error", "El tamaño de sus archivos supera los 500 Kb, envíe menos archivos o envíe un correo electrónico desde su buzón personal.", "error");
            return false
          }
            // je veux tout ziper
            // voire la taille du zip puis voir si ça depasse la limite de emailjs
            // puis faire une fenetre si jamais ça depasse
            // retourner le zip sinon
      }

      validateEmail(email)  
      {  
        var atposition=email.indexOf("@");  
        var dotposition=email.lastIndexOf(".");  
        if (atposition<1 || dotposition<atposition+2 || dotposition+2>=email.length){
            swal("Error", "Por favor, introduzca una dirección de e-mail valida.", "error");
            return false;  
        }else{
            return true;
        }
      } 
      validateMessage(message)  
      {  
        if(message === ""){
            swal("Attention", "El mensaje está vacio, no se enviará el e-mail.", "error");
            return false
        }else if(message.length > 1000000){
            swal("Attention", "Su mensaje excede el número máximo de caracteres. Reduzca el tamaño de su mensaje o utilice su buzón personal.", "error");
            return false
        }else{
            return true
        }
      } 
      validateName(name)  
      {  
        if(name === ""){
            swal("Attention", "Por favor, introduzca su nombre.", "error");
            return false
        }else{
            return true
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }

      async sendMail(e) {
        e.preventDefault();
        //console.log(this.state)
        //console.log(this.state.files)
        // var filesT = this.state.files;
        // var fileT;
        // var base64;

        // // on parcourt les fichiers
        // for (var i = 0; i < filesT.length; i++) {
        //     // on récupère le i-ème fichier
        //     fileT = filesT.item(i);
        //     console.log(fileT);
        // }
        
        // let reader = new FileReader();
        // await reader.readAsDataURL(fileT);
        // reader.onloadend = () => {
        //     this.setState({
        //         files: reader.result
        //     });
        //     console.log("here")
        // };
        // console.log(this.state)
        // let reader = new FileReader();
        // reader.readAsDataURL(this.state.files.files[0]);
        // reader.onload = () => {
        //     // Make a fileInfo Object
        //     var baseURL = reader.result;
        //     console.log(baseURL);
        //   };

        //this.validateFiles(this.state.files) // extrair d'ici le zip a envoyer
        if(this.validateMessage(this.state.message) && this.validateEmail(this.state.email) && this.validateName(this.state.name)){
            let templateParams = {
                name:this.state.name,
                email:this.state.email,
                message:this.state.message,
                files:this.state.files
            }

            //console.log(templateParams)

            emailjs.send(
                this.service_id,
                this.template_id,
                templateParams,
                this.user_id
            )
            swal("Su mensaje ha sido enviado correctamente.", "Le responderemos muy pronto. Se le enviará igualmente una copia de su mensaje.", "success");
            this.resetForm()
        }
        else{
            return false;
        }
      }
    
      resetForm(){
        this.setState({
            name: "",
            email: "",
            message: "",
            files: ""
          })
      }
    
      render() {
        return(
            <div className="FooterWithContactWrapper">
                <header className="TitleWrapper">
                    <h2>Contacto</h2>
                </header>
                <div className="ContactFormContainer">
                    <div className="ContactForm">
                        <div className="FirstLine">
                            <input name="name" placeholder="Nombre" value={this.state.name} type="text" autoComplete="off"  onChange={this.handleInputChange}/>
                            <input name="email" placeholder="Email" value={this.state.email} type="text" autoComplete="off"  onChange={this.handleInputChange}/>
                        </div>
                        <div className="MessageContents">
                            <textarea name="message" placeholder="Mensaje" value={this.state.message} type="text"  onChange={this.handleInputChange}/>
                            <div className="SubmitFiles">
                                {/* <input type="file" id="files" name="files" multiple  onChange={this.handleInputChange}/> */}
                                <button onClick={this.sendMail}>Enviar</button>
                            </div>
                        </div>
                    </div>
                    <div className="Contacts">
                        <div className="elementContactMail">
                            <a target="blank" href="mailto:info@arrasa.org"><img alt="mail" src={mail}/></a>
                            <a target="blank" href="mailto:info@arrasa.org">info@arrasa.org</a>
                        </div>
                        <div className="elementContactRedes">
                            <a target="blank" href="https://www.facebook.com/people/Arrasa-Producci%C3%B3n-Distribuci%C3%B3n/100087961219528/"><img alt="facebook" src={facebook}/></a>
                            <a target="blank" href="https://www.linkedin.com/company/arrasa-producci%C3%B3n-distribuci%C3%B3n/"><img alt="linkedin" src={linkedin}/></a>
                            <a target="blank" href="https://www.instagram.com/arrasa_artesescenicas/?igshid=YmMyMTA2M2Y%3D" ><img alt="instagram" src={instagram}/></a>
                        </div>
                    </div>
                </div>

                <div className="InfoSubvencion">
                    <div className="InfoSubvencionUEImagen">
                        <img alt="logo" src={logoUE} />
                    </div> 
                    <div className="InfoSubvencionTexto">  
                        <p>PROTECOM.<br/>Subvenciones para el mantenimiento del empleo, la transición ecológica y la transformación digital.<br/>Año: 2022. Subvención de 5.000 €<br/>Plan de Recuperación, Transformación y Resiliencia financiado por la Unión Europea - Next Generation EU (PRTR-NG).</p>
                    </div>
                </div>

                <div className="InfoJunta">
                    <div className="InfoJuntaImagen">
                        <img alt="logo" src={logo} />
                    </div> 
                    <div className="InfoJuntaTexto">  
                        <p>Esta actividad económica ha sido subvencionada al amparo de la orden IEM/1292/2022, de 22 de septiembre, de la consejería de industria, comercio y empleo, por la que se establecen las bases reguladoras de las subvenciones destinadas a fomentar el autoempleo en Castilla y León. <br/>Objetivo: conseguir un empleo y formación de calidad.</p>
                    </div>
                </div>

                

                <div className="CopyrightContainer">
                    <p>©2023 ARRASA PRODUCCION & DISTRIBUCION. All rights reserved.</p>
                </div> 
                

            </div>
        )
      }
    }

export default FooterWithContact