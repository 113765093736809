import React, { Component } from 'react';
import './AboutUs.css';


class AboutUs extends Component {
    
    render() {
        return(
            <div className="aboutUsWrapper">
                <div className="textWrapper">
                    <div className="titleWrapper">
                        <h1>¿Quiénes somos?</h1>
                    </div>
                    <p>
                        ARRASA nació en 2022 como una iniciativa dirigida por Asunción Blanco, productora y distribuidora de artes escénicas y audiovisuales durante más de 25 años. <br></br><br></br>Un proyecto del que participé desde su gestación con ilusión por contribuir con nuestro trabajo a crear una sociedad que respete y valore la cultura como algo que nos concierne a todos.<br></br><br></br>El fallecimiento de Asun ha depositado sobre mis hombros la responsabilidad y el honor de seguir al frente de un proyecto profesional que ofrece a nuestras compañías: dedicación, esfuerzo y rigor. En ARRASA, se mima a las compañías, se las acompaña, se las escucha y se trabaja para hacer realidad sus ilusiones de llegar al mayor público posible. ARRASA ambiciona el trabajo bien hecho y, para ello, utilizamos la premisa de distribuir un número muy limitado de compañías. El valor que posee ARRASA es, ofrecer al espectador espectáculos de calidad que vayan más allá del mero entretenimiento, que inviten a la reflexión o que ofrezcan una visión distinta de las cosas.
                    </p>
                </div>
            </div>
        )
    }
}

export default AboutUs