import React, { Component } from 'react';
import './Team.css';
import {Profiles} from './profiles.js'

class Team extends Component {
    
    render() {
        return(
            <div className="teamWrapper">

                <div className="titleWrapper">
                    <h1>Nuestro Equipo</h1>
                </div>

                <div className="teamPeopleWrapper">
                    {Profiles.map((item, index) => {
                        if(index%2===0)
                        {
                            return (
                                <div className="personWrapperTextRight" key={index+1000}>
                                    <div className="imageWrapper">
                                        <center><img src={item.image} alt="profilePicture"></img></center>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="textWrapper">
                                        <h1>{item.Name}</h1>
                                        <div className="paragraph" dangerouslySetInnerHTML={{__html: item.description}}></div>
                                    </div>
                                </div>
                            )
                        }
                        if(index%2 === 1)
                        {
                            return (
                                <div className="personWrapperTextLeft" key={index+1051}>
                                    <div className="imageWrapper">
                                        <center><img src={item.image} alt="profilePicture"></img></center>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="textWrapper">
                                        <h1>{item.Name}</h1>
                                        <div className="paragraph" dangerouslySetInnerHTML={{__html: item.description}}></div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
}

export default Team